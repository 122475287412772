export const getNormalisedPrice = (price?: string | number): string => {
    if (!price || typeof +price !== 'number' || !+price) {
        return '';
    }

    const number = Number(price);
    const isInteger = Number.isInteger(number);

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: isInteger ? 0 : 2,
        minimumFractionDigits: isInteger ? 0 : 2,
    }).format(number);
};
