export const Googlewallet = () => {
    return (
        <svg
            width="75"
            height="45"
            viewBox="0 0 75 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_27_130880)">
                <path
                    d="M3.75 0.5H71.25C73.0449 0.5 74.5 1.95507 74.5 3.75V41.25C74.5 43.0449 73.0449 44.5 71.25 44.5H3.75C1.95507 44.5 0.5 43.0449 0.5 41.25V3.75C0.5 1.95507 1.95507 0.5 3.75 0.5Z"
                    fill="white"
                    stroke="#c4c4c4"
                />
                <path
                    d="M38.5071 35.9171C38.4407 35.9569 38.3876 35.9968 38.3213 36.0233C38.1354 36.1029 37.9363 36.1295 37.7771 36.1295C37.5913 36.1295 37.3125 36.1162 37.0205 35.9038C36.6223 35.6251 36.4365 35.134 36.4365 34.7093C36.4365 33.8333 37.1533 33.3953 37.7373 33.3953C37.9363 33.3953 38.1487 33.4484 38.3213 33.5546C38.6 33.7404 38.6796 33.9793 38.7194 34.112L37.3922 34.6562L36.9542 34.696C37.1002 35.4128 37.578 35.8375 38.1222 35.8375C38.4142 35.8375 38.6265 35.7313 38.8123 35.6384L38.5071 35.9171ZM37.9762 34.2315C38.0823 34.1917 38.1354 34.1518 38.1354 34.0722C38.1354 33.8466 37.8833 33.5811 37.578 33.5811C37.3523 33.5811 36.9276 33.7537 36.9276 34.3642C36.9276 34.4571 36.9409 34.5633 36.9409 34.6695L37.9762 34.2315Z"
                    fill="#1476C6"
                />
                <path
                    d="M36.3041 36.0365H35.2954C35.4281 35.8639 35.4546 35.8507 35.4546 35.7312V34.8021C35.4546 34.3641 35.4546 33.727 35.4812 33.143C35.4812 32.851 35.5077 32.4927 35.521 32.2272H35.083L35.5077 32.0281H36.3174C36.1448 32.1343 36.0785 32.1608 36.0652 32.3599C36.0254 32.7979 36.0121 33.4881 36.0121 34.2977V35.6118C36.0121 35.8374 36.0519 35.8639 36.2377 35.8772C36.3307 35.8905 36.4368 35.8905 36.5297 35.9038L36.3041 36.0365Z"
                    fill="#1476C6"
                />
                <path
                    d="M34.4459 33.6737C34.5786 33.7799 34.8441 34.0056 34.8441 34.4436C34.8441 34.8683 34.6052 35.0674 34.3663 35.2532C34.2866 35.3328 34.207 35.4125 34.207 35.5319C34.207 35.6514 34.2866 35.731 34.353 35.7708L34.5521 35.9301C34.8043 36.1425 35.0299 36.3283 35.0299 36.7265C35.0299 37.2574 34.5123 37.8016 33.5434 37.8016C32.7204 37.8016 32.3223 37.4166 32.3223 36.9919C32.3223 36.7928 32.4284 36.5008 32.7603 36.3017C33.1186 36.0894 33.5832 36.0628 33.8486 36.0363C33.769 35.9301 33.6761 35.8239 33.6761 35.6514C33.6761 35.5585 33.7026 35.4921 33.7292 35.4257C33.6628 35.4257 33.6097 35.439 33.5434 35.439C32.9461 35.439 32.601 34.9877 32.601 34.5497C32.601 34.2843 32.7204 33.9923 32.9594 33.7932C33.2779 33.5277 33.6628 33.4746 33.9814 33.4746H35.1494L34.791 33.6737H34.4459ZM34.0477 36.1956C34.0079 36.1956 33.9681 36.1956 33.915 36.1956C33.8619 36.1956 33.5566 36.2088 33.3177 36.2885C33.1983 36.3283 32.8266 36.4743 32.8266 36.8725C32.8266 37.2706 33.2248 37.5626 33.8221 37.5626C34.3663 37.5626 34.6583 37.2972 34.6583 36.9521C34.6583 36.6601 34.4725 36.5008 34.0477 36.1956ZM34.207 35.1205C34.3397 34.9877 34.353 34.8152 34.353 34.709C34.353 34.2976 34.1141 33.6605 33.6363 33.6605C33.4903 33.6605 33.331 33.7401 33.2381 33.8463C33.1452 33.9657 33.1186 34.125 33.1186 34.271C33.1186 34.6559 33.3443 35.2797 33.8221 35.2797C33.9681 35.2797 34.1141 35.2001 34.207 35.1205Z"
                    fill="#1476C6"
                />
                <path
                    d="M30.9282 36.1162C30.0257 36.1162 29.5479 35.4128 29.5479 34.7757C29.5479 34.0324 30.1584 33.3953 31.0211 33.3953C31.8573 33.3953 32.375 34.0457 32.375 34.7358C32.375 35.4128 31.8573 36.1162 30.9282 36.1162ZM31.645 35.6517C31.7777 35.4658 31.8175 35.2402 31.8175 35.0278C31.8175 34.5235 31.5786 33.5811 30.8751 33.5811C30.6893 33.5811 30.5035 33.6607 30.3708 33.7669C30.1451 33.966 30.1053 34.2182 30.1053 34.4571C30.1053 35.0146 30.384 35.9437 31.0742 35.9437C31.2866 35.9437 31.5122 35.8375 31.645 35.6517Z"
                    fill="#1476C6"
                />
                <path
                    d="M27.9155 36.1162C27.013 36.1162 26.5352 35.4128 26.5352 34.7757C26.5352 34.0324 27.1457 33.3953 28.0084 33.3953C28.8446 33.3953 29.3623 34.0457 29.3623 34.7358C29.3623 35.4128 28.8446 36.1162 27.9155 36.1162ZM28.619 35.6517C28.7517 35.4658 28.7915 35.2402 28.7915 35.0278C28.7915 34.5235 28.5526 33.5811 27.8492 33.5811C27.6633 33.5811 27.4775 33.6607 27.3448 33.7669C27.1192 33.966 27.0793 34.2182 27.0793 34.4571C27.0793 35.0146 27.3581 35.9437 28.0483 35.9437C28.2606 35.9437 28.4995 35.8375 28.619 35.6517Z"
                    fill="#1476C6"
                />
                <path
                    d="M26.1633 35.9172L25.4067 36.0897C25.1015 36.1428 24.8227 36.1826 24.5307 36.1826C23.0575 36.1826 22.5 35.1075 22.5 34.2581C22.5 33.2228 23.2964 32.2672 24.6502 32.2672C24.9422 32.2672 25.2076 32.307 25.4598 32.3733C25.858 32.4795 26.0438 32.6255 26.1633 32.7052L25.7253 33.1299L25.5395 33.1697L25.6722 32.9573C25.4864 32.7848 25.1678 32.453 24.5307 32.453C23.6945 32.453 23.0575 33.0901 23.0575 34.0192C23.0575 35.0146 23.7875 35.957 24.9422 35.957C25.2873 35.957 25.4598 35.8906 25.6191 35.8242V34.9748L24.8227 35.0146L25.2475 34.789H26.3756L26.2429 34.9217C26.2031 34.9482 26.2031 34.9615 26.1898 35.0013C26.1898 35.0544 26.1766 35.2004 26.1766 35.2535V35.9172"
                    fill="#1476C6"
                />
                <path
                    d="M40.949 36.0363H40.5508L39.9668 33.4614H40.2853L40.7632 35.7709L41.2675 33.4614H41.679L42.1833 35.7709L42.6612 33.4614H42.9797L42.3957 36.0363H41.9975L41.4932 33.7402L40.949 36.0363Z"
                    fill="#1476C6"
                />
                <path
                    d="M44.8383 34.258C44.8383 33.8332 44.6525 33.674 44.2277 33.674C43.8959 33.674 43.6968 33.8598 43.657 34.1518L43.365 34.112C43.4181 33.6872 43.7499 33.4085 44.241 33.4085C44.8117 33.4085 45.1303 33.6474 45.1303 34.2181V35.5321C45.1303 35.7578 45.1436 35.9038 45.1834 36.0365H44.8914C44.8648 35.9701 44.8516 35.8374 44.8516 35.7711C44.7321 35.9569 44.4401 36.1029 44.1747 36.1029C43.6968 36.1029 43.3252 35.8109 43.3252 35.2401C43.3252 34.7092 43.6968 34.4172 44.241 34.4172C44.5065 34.4172 44.7188 34.4703 44.8516 34.5234V34.258H44.8383ZM44.8383 34.8154C44.6923 34.7491 44.4932 34.6827 44.2277 34.6827C43.8694 34.6827 43.6172 34.8685 43.6172 35.2401C43.6172 35.6383 43.8561 35.8241 44.1879 35.8241C44.4799 35.8241 44.7454 35.6118 44.8516 35.3861V34.8154"
                    fill="#1476C6"
                />
                <path d="M45.7139 36.0362H46.0059V32.373H45.7139V36.0362Z" fill="#1476C6" />
                <path d="M46.6299 36.0362H46.9219V32.373H46.6299V36.0362Z" fill="#1476C6" />
                <path
                    d="M48.3689 35.8241C48.6875 35.8241 48.9131 35.6383 48.9662 35.1605L49.2582 35.2003C49.1785 35.8241 48.8335 36.1029 48.3556 36.1029C47.7451 36.1029 47.3867 35.6516 47.3867 34.7358C47.3867 33.9129 47.692 33.4085 48.3291 33.4085C48.9927 33.4085 49.2582 33.966 49.2582 34.7092V34.8021H47.692C47.692 35.5189 47.9574 35.8241 48.3689 35.8241ZM48.9529 34.55C48.9396 34.0589 48.7405 33.6872 48.3424 33.6872C47.9442 33.6872 47.7318 34.0191 47.7053 34.55H48.9529Z"
                    fill="#1476C6"
                />
                <path
                    d="M50.6112 36.0234C50.5448 36.0499 50.4254 36.0765 50.3059 36.0765C49.921 36.0765 49.8015 35.8907 49.8015 35.519V33.7405H49.4697V33.475H49.8015V32.6521L50.0935 32.5327V33.475H50.6112V33.7405H50.1068V35.4792C50.1068 35.7181 50.1599 35.7978 50.359 35.7978C50.4652 35.7978 50.5448 35.7712 50.6245 35.7447V36.0234"
                    fill="#1476C6"
                />
                <path
                    d="M33.1578 17.4146C31.8969 15.6891 30.1581 14.1627 28.1672 13.1009C27.8619 12.9416 27.5301 12.8487 27.185 12.8487C26.4152 12.8487 25.7117 13.2735 25.3401 13.9504C24.7959 14.9591 25.1808 16.2333 26.1896 16.7775C29.2423 18.41 31.2199 21.5291 31.4456 25.1393V25.126C31.4058 24.7279 31.4721 24.3297 31.6579 23.958C31.6845 23.9049 31.711 23.8518 31.7509 23.7855C32.7065 22.2193 33.2109 20.4142 33.2109 18.556C33.2241 18.1844 33.1976 17.7995 33.1578 17.4146Z"
                    fill="#1476C6"
                />
                <path
                    d="M45.2493 13.4993C44.8776 11.88 44.3334 10.3138 43.6167 8.814C43.2318 8.01764 42.4222 7.5 41.5329 7.5C41.1878 7.5 40.856 7.57964 40.5507 7.72564C39.4092 8.26982 38.9181 9.65019 39.4623 10.7916C40.6436 13.2604 41.2143 15.8618 41.2143 18.5695C41.2143 21.2771 40.6303 23.8786 39.4889 26.3075C39.4756 26.3473 39.2765 26.7721 39.2765 27.2764C39.2765 27.4092 39.2898 27.6215 39.3296 27.8073C39.4756 28.4577 39.9003 29.055 40.5905 29.3735C40.8958 29.5195 41.2276 29.5992 41.5727 29.5992C42.4089 29.5992 43.192 29.1346 43.5902 28.4046C44.0547 27.5419 44.5192 26.4137 44.9174 24.9537C45.0502 24.5157 45.1563 24.1042 45.2493 23.653C45.6209 22.0071 45.82 20.3082 45.82 18.5695C45.82 16.8573 45.6342 15.1451 45.2493 13.4993Z"
                    fill="#47AE33"
                />
                <path
                    d="M35.5341 11.0836C35.4943 11.0172 35.4412 10.9509 35.4014 10.8845C35.2554 10.6854 35.0696 10.5129 34.8572 10.3801C34.5121 10.1545 34.0874 10.0218 33.6626 10.0218C33.3043 10.0218 33.1317 10.0616 32.8795 10.1678C32.6141 10.2739 32.3486 10.4067 32.0964 10.6721C31.8443 10.9376 31.6983 11.203 31.5921 11.4818C31.3797 12.0392 31.4063 12.7692 31.7779 13.3532C32.5477 14.5876 32.999 15.9812 33.1583 17.4147C33.1981 17.7996 33.2246 18.1845 33.2246 18.5694C33.2246 20.4143 32.7203 22.2194 31.7646 23.7989C31.7248 23.852 31.6983 23.9051 31.6717 23.9714C31.4859 24.3431 31.4195 24.7545 31.4594 25.1394C31.5257 25.8031 31.8841 26.4402 32.5079 26.8118C32.6672 26.9047 32.8397 26.9844 33.0123 27.0375C33.2246 27.1038 33.437 27.1304 33.6494 27.1304C34.4192 27.1304 35.1226 26.7322 35.5208 26.0685C36.7286 24.0776 37.4321 21.8345 37.5781 19.5118C37.6047 19.1932 37.6047 18.888 37.6047 18.5694C37.6179 15.9281 36.8879 13.34 35.5341 11.0836Z"
                    fill="#ED114C"
                />
                <path
                    d="M39.4892 26.3071C40.644 23.8782 41.2147 21.2767 41.2147 18.5691C41.2147 17.64 41.1483 16.9631 41.1085 16.5251C39.5954 14.1227 37.4054 11.9725 34.8438 10.3931C34.8438 10.3931 34.8968 10.4329 34.9101 10.4329C35.0827 10.5656 35.2419 10.6983 35.3879 10.8974C35.4278 10.9505 35.4808 11.0169 35.5207 11.0965C36.8878 13.3529 37.6045 15.9411 37.6045 18.5824C37.6045 18.9009 37.5912 19.2195 37.5779 19.5247C38.6663 21.6086 39.2636 23.9711 39.2636 26.4531C39.2636 26.6522 39.2636 26.9708 39.2636 27.1964C39.2769 26.9309 39.3432 26.6655 39.4361 26.4266C39.4627 26.3868 39.4759 26.3469 39.4892 26.3071Z"
                    fill="#FBBF16"
                />
            </g>
            <defs>
                <clipPath id="clip0_27_130880">
                    <rect width="75" height="45" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
