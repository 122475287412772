export const Visa = () => {
    return (
        <svg
            width="75"
            height="45"
            viewBox="0 0 75 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_27_130878)">
                <path
                    d="M3.75 0.5H71.25C73.0449 0.5 74.5 1.95507 74.5 3.75V41.25C74.5 43.0449 73.0449 44.5 71.25 44.5H3.75C1.95507 44.5 0.5 43.0449 0.5 41.25V3.75C0.5 1.95507 1.95507 0.5 3.75 0.5Z"
                    fill="white"
                    stroke="#c4c4c4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.1179 29.6597L33.4242 15.3544H37.1104L34.8042 29.6606H31.1179V29.6597ZM48.1748 15.7059C47.4464 15.4191 46.2998 15.1059 44.872 15.1059C41.2279 15.1059 38.662 17.0466 38.6414 19.8262C38.6179 21.8831 40.4714 23.0297 41.8692 23.7131C43.3054 24.4134 43.7882 24.8625 43.7817 25.4887C43.7723 26.4469 42.6361 26.8856 41.5757 26.8856C40.1001 26.8856 39.3164 26.67 38.1042 26.1356L37.6298 25.9078L37.1142 29.1094C37.9739 29.5078 39.5676 29.8537 41.2204 29.8725C45.0942 29.8725 47.6104 27.9544 47.6404 24.9862C47.6536 23.355 46.6711 22.1194 44.5439 21.0994C43.2557 20.4356 42.4654 19.9959 42.4748 19.3247C42.4748 18.7303 43.1423 18.0937 44.5861 18.0937C45.5349 18.0721 46.4769 18.2597 47.3451 18.6431L47.6761 18.8072L48.1748 15.7059ZM53.0836 24.5878C53.3892 23.7656 54.5517 20.5894 54.5517 20.5894C54.5301 20.6269 54.8554 19.7606 55.042 19.2244L55.2904 20.4581C55.2904 20.4581 55.9982 23.8734 56.1436 24.5878H53.0836ZM57.6304 15.3694H54.7823C53.8992 15.3694 53.2373 15.6225 52.8511 16.5553L47.3761 29.6653H51.2479C51.2479 29.6653 51.8789 27.9019 52.0223 27.5147L56.7436 27.5222C56.8542 28.0209 57.1936 29.6653 57.1936 29.6653H60.6145L57.6314 15.3684L57.6304 15.3694ZM28.0251 15.3666L24.4176 25.1194L24.0314 23.1366C23.3592 20.8528 21.2657 18.3769 18.9248 17.1366L22.2248 29.6475L26.1267 29.6456L31.9317 15.3656H28.0251V15.3666Z"
                    fill="#0056A3"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.0677 15.3563H15.1239L15.0752 15.6535C19.6999 16.8385 22.7608 19.6988 24.0311 23.1366L22.7393 16.5647C22.5161 15.6572 21.8683 15.3881 21.0677 15.3572V15.3563Z"
                    fill="#EC982D"
                />
            </g>
            <defs>
                <clipPath id="clip0_27_130878">
                    <rect width="75" height="45" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
